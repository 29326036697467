.p-container{
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logos{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 100px;
    /* border: 2px solid red; */
    flex-wrap: wrap;
    box-sizing: border-box;
    flex: 1 1;
    gap: 1.5rem;
}


.block{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.5rem 0 1.5rem;
}

.block img{
    margin-bottom: 0.8rem;
}

.block span{
    font-size: 1rem;
    font-weight: 500;
    color: #0067b8;
}
