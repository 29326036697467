*{
  font-family: 'Manrope', sans-serif;
  font-size: 62.5;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
}

a{
  text-decoration: none;
  color: rgb(41, 41, 41);
}
