.s-container{
    margin-left: 4rem;
    margin-right: 1rem;
}

.social{
   width: 90%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex: 1;
    
} 

.social span{
    margin-right: 1rem;
}



@media (max-width : 1082px) {
    .s-container{
        margin-left: 1rem;
    }
    
}


@media (max-width : 394) {
    .s-container{
        margin-left: 1rem;
        text-align: left;
    }
    
}