.d-container{
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
    /* box-sizing: border-box; */
    overflow: hidden;
}

.d-container img{
    width: 95%;
}