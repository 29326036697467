.b-container{
    margin-right: 1rem;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    
}

.cards{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 22rem;
    box-sizing: border-box;
}

.cards img{
    width: 100%;
}

.blog{
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    width: 93%;
    
}


.cards>:nth-child(2) {
    font-size: 1.8125rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.cards>:nth-child(3) {
    font-size: 0.98rem;
    color: rgb(89, 89, 89);
}



.cards>:nth-child(4) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #0067b8;
    font-weight: 400;
}

@media (max-width : 1082px) {
    .blog{
        flex-wrap: wrap;
        flex: 1 1;
    }


}