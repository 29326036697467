.h-container{
    display: flex;
    justify-content: space-between;
  }
  
  .left-side{
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-wrap: wrap;
  }

  .ham{
    display: none;
  }
  

  .wrapper{
    margin-top: 1.3rem;
    margin-right: 4rem;
    margin-left: 4rem;
    z-index: 99;
  }

  .tag{
    display: flex;
    gap: 0.5rem;
  }

  .right-nav{  
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
  }

  .right-side{
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
  }

  .tag a{
    font-size: 0.85rem;
  }

  .right-nav a{
    font-size: 0.85rem;
  }

  @media (max-width:900px) {
    .ham{
      display: block;
      cursor: pointer;
    }
    .ham .line{
      width: 30px;
      height: 3px;
      background: rgb(113, 113, 113);
      margin: 6px 0;
    }

    .tag{
      display: none;
    }

    .right-nav {
      height: 25rem;
      position: absolute;
      top: 4rem;
      left: 0;
      right: 0;
      background: rgb(211, 211, 211);
      z-index: 90;
      flex-direction: column;
      align-items: center;
      text-align: left !important;
      justify-content: center;
      transition: 0.5s;
      transform: translateY(0px);
    }

     .right-nav span{
      margin-top: 2rem;
      
    }

    /* nav active */
    
    .nav_active{
      transform: translateY(-1000px);
    }
    
  }