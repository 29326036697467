.f-container{
    margin-top: 4em;
    margin-bottom: 1.3rem;
    background-color: #ededed;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-right: 1rem;
    padding-left: 2rem;
        display: flex;
    justify-content: center;
  }

  .foot{
    display: flex;
    min-width: 90%;
    justify-content: space-between;
  }

  
  .col{
    margin-right: 4rem;
  }

  .last{
    margin-right: 0;
  }


  .col{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.5rem;
  }

  .col span{
    font-size: 0.7rem;
    color: #616161;
  }

 

  .col>:nth-child(1){
    font-weight: 600;
      font-size: 0.9rem;
    color: #616161;
  }


  @media (max-width : 1082px) {
    .foot{
        flex-wrap: wrap;
        flex: 1 1
    }
    .foot{
      gap: 1rem;
  }
}